import React, {useEffect, useRef} from "react";
import {
  useLocation,
} from "react-router-dom";
import * as THREE from 'three';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Viewer360 = () => {
    let query = useQuery();
    const imageLink = query.get("imageLink");
    const ref = useRef();

    console.log("query", query);
    
    useEffect(() => {
        let camera, scene, renderer;

        let isUserInteracting = false,
            onPointerDownMouseX = 0, onPointerDownMouseY = 0,
            lon = 0, onPointerDownLon = 0,
            lat = 0, onPointerDownLat = 0,
            phi = 0, theta = 0;

        if (ref.current && imageLink) {
            init();
            animate();
        }

        function init() {
            // const container = document.getElementById( 'container' );
            const container = ref.current;
            camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1100 );
            scene = new THREE.Scene();
            const geometry = new THREE.SphereGeometry( 500, 60, 40 );
            // invert the geometry on the x-axis so that all of the faces point inward
            geometry.scale( - 1, 1, 1 );

            const texture = new THREE.TextureLoader().load( 'https://aws.sayhomee.com/kitchen-design'+imageLink );
            // for testing purposes
            // const texture = new THREE.TextureLoader().load( '/kitchen_equirectangular_8k.jpg' );
            const material = new THREE.MeshBasicMaterial( { map: texture } );
            const mesh = new THREE.Mesh( geometry, material );
            scene.add( mesh );

            renderer = new THREE.WebGLRenderer();
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( window.innerWidth, window.innerHeight );
            container.appendChild( renderer.domElement );
            container.style.touchAction = 'none';
            container.addEventListener( 'pointerdown', onPointerDown );
            container.addEventListener( 'wheel', onDocumentMouseWheel );
            window.addEventListener( 'resize', onWindowResize );
        }

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize( window.innerWidth, window.innerHeight );
        }

        function onPointerDown( event ) {
            if ( event.isPrimary === false ) return;
            isUserInteracting = true;
            onPointerDownMouseX = event.clientX;
            onPointerDownMouseY = event.clientY;
            onPointerDownLon = lon;
            onPointerDownLat = lat;
            document.addEventListener( 'pointermove', onPointerMove );
            document.addEventListener( 'pointerup', onPointerUp );
        }

        function onPointerMove( event ) {
            if ( event.isPrimary === false ) return;
            lon = ( onPointerDownMouseX - event.clientX ) * 0.1 + onPointerDownLon;
            lat = ( event.clientY - onPointerDownMouseY ) * 0.1 + onPointerDownLat;
        }

        function onPointerUp(e) {
            if ( e.isPrimary === false ) return;
            isUserInteracting = false;
            document.removeEventListener( 'pointermove', onPointerMove );
            document.removeEventListener( 'pointerup', onPointerUp );
        }

        function onDocumentMouseWheel( event ) {
            const fov = camera.fov + event.deltaY * 0.05;
            camera.fov = THREE.MathUtils.clamp( fov, 10, 75 );
            camera.updateProjectionMatrix();
        }

        function animate() {
            requestAnimationFrame( animate );
            update();
        }

        function update() {
            if ( isUserInteracting === false ) {
                lon += 0.05;
            }

            lat = Math.max(-85, Math.min( 85, lat ) );
            phi = THREE.MathUtils.degToRad( 90 - lat );
            theta = THREE.MathUtils.degToRad( lon );

            const x = 500 * Math.sin( phi ) * Math.cos( theta );
            const y = 500 * Math.cos( phi );
            const z = 500 * Math.sin( phi ) * Math.sin( theta );

            camera.lookAt( x, y, z );

            renderer.render( scene, camera );
        }
    }, [ref, imageLink]);

    console.log("rendering viewer360");
    return (
        <div ref={ref}></div>
    );
};

export default Viewer360;