import "antd/dist/antd.css";
import styled from 'styled-components';
import {useState, useRef, useEffect, Suspense} from 'react';
import {
  ExpandOutlined,
  CheckOutlined,
  SyncOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import {useTransition, useSpring, animated} from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import { Typography, Spin } from 'antd';

const { Text } = Typography;
const mobile_tablet_width = 1224;
const image_host = 'https://aws.sayhomee.com/kitchen-design'; // production
// const image_host = '/S3'; // local

/* Generic Styles */

const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 30px;
  margin: 16px;
  opacity: 0.5;
  @media (max-width: ${mobile_tablet_width}px) {
    height: 20px;
  }
`;

const AppContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  overflow: hidden;
  background: #e0e0e0;
  @font-face {
    font-family: Inter;
    src: url('/Fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }
  @font-face {
    font-family: Inter;
    src: url('/Fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
  }
  * {
    font-family: Inter, Helvetica, sans-serif;
  }
`;

const Button = styled.button`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  padding: 16px 20px;
  margin: 16px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  backdrop-filter: blur(10px);
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  @media (max-width: ${mobile_tablet_width}px) {
    padding: 12px 16px;
  }
`;

/* Background Image */

const StyledBackgroundDiv = styled.div`
  background: url('${props => props.backgroundImageUrl}') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;
const StyledSpinnedContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
`;

const imgCache = {
  __cache: {},
  read(src) {
    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          this.__cache[src] = true;
          resolve(this.__cache[src]);
          // make loading spinner more obvious while testing
          // setTimeout(() => {
          //   resolve(this.__cache[src]);
          // }, 5000);
        };
        img.src = src;
      }).then((img) => {
        this.__cache[src] = true;
      });
    }
    if (this.__cache[src] instanceof Promise) {
      throw this.__cache[src];
    }
    return this.__cache[src];
  }
}
export const Background = ({ backgroundImageUrl }) => {
  // download image to cache, let suspense show loading spinner
  imgCache.read(backgroundImageUrl);
  // anime component fade in on first mount
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })
  return <animated.h1 style={props}><StyledBackgroundDiv backgroundImageUrl={backgroundImageUrl} /></animated.h1>;
}

/* Menu */

const StyledMenu = styled.div`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  display: flex;
  flex-flow: column;
  width: 240px;
  height: 100%;
  overflow: hidden;
  backdrop-filter: blur(10px);
`;

const MenuButton = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  background: rgba(255, 255, 255, 0.01);
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 20px 18px;
  outline: none;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  @media (max-width: ${mobile_tablet_width}px) {
    padding: 12px 16px;
  }
`;
const MenuGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;  /* Hide scrollbar for Chrome, Safari and Opera */
  }
`;
const MenuGridCell = styled.div`
  background: transparent;
  width: 50%;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;
const MenuGridCellInner = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 8px 16px;
  height: 104px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  ${props => props.selected && 
    `background: rgba(255, 255, 255, 0.4);
     border-radius: 8px;`
  }
  &:hover {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 8px;
  }
`;
const MenuGridStyleImage = styled.img`
  width: 48px;
  height: 48px;
  display: cover;
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 8px;
`;
const MenuArrowButton = styled(Button)`
  padding: 8px;
  border-radius: 4px;
  backdrop-filter: none;
`;

const Menu = ({showMenu, selections, setSelections, onCloseMenu}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const node = useRef();
  useEffect(() => {
    const handleClick = e => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      onCloseMenu();
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onCloseMenu]);

  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${mobile_tablet_width}px)` })
  const gridRef = useRef();
  // eslint-disable-next-line
  const [props, set, stop] = useSpring(() => ({
    immediate: false,
    y: 0,
    onFrame: props => {
      if (gridRef.current)
        gridRef.current.scrollTop = props.y;
    },
  }));
  const scrollDown = () => {
    const newScrollTop = Math.min(gridRef.current.scrollTop + gridRef.current.offsetHeight, gridRef.current.scrollHeight - gridRef.current.offsetHeight);
    console.log("newScrollTop", newScrollTop);
    set({y: Math.min(gridRef.current.scrollTop + gridRef.current.offsetHeight, gridRef.current.scrollHeight - gridRef.current.offsetHeight)});
  }
  const scrollUp = () => {
    const newScrollTop = Math.max(gridRef.current.scrollTop - gridRef.current.offsetHeight, 0);
    console.log("newScrollTop", newScrollTop);
    set({y: newScrollTop});
  }

  const isCountertop = showMenu.catalogName === 'countertops';
  const selectionKey = isCountertop ? 'countertop' : 'panel';

  return (
    <StyledMenu ref={node}>
      {showMenu.catalog.map((menu, i) => {
        return (
          <MenuButton key={i} onClick={() => setSelectedIndex(i)}>{i === selectedIndex ? <CheckOutlined/> : <div style={{width:14, height: 14, display: 'inline-block'}}/>} {menu.name}</MenuButton>
        )
      })}
      {!isTabletOrMobile && <MenuArrowButton onClick={scrollUp}><ArrowUpOutlined /></MenuArrowButton>}
      <MenuGrid ref={gridRef}>
        {showMenu.catalog[selectedIndex].items.map((style, i) => {
          return (
            <MenuGridCell key={i}>
              <MenuGridCellInner selected={selections[selectionKey] === style.name} onClick={() => setSelections({...selections, [selectionKey]: style.name})}>
                <MenuGridStyleImage src={`${image_host}/Thumbnails/${showMenu.catalog[selectedIndex].name}/${style.name}_thumbnail_128x128.jpg`} loading="lazy"/>
                <Text style={{paddingLeft: 4, paddingRight: 4, textAlign:"center"}}>{style.name}</Text>
              </MenuGridCellInner>
            </MenuGridCell>
          )
        })}
      </MenuGrid>
      {!isTabletOrMobile && <MenuArrowButton onClick={scrollDown}><ArrowDownOutlined /></MenuArrowButton>}
    </StyledMenu>
  )
}

/* Tooltip */
const StyledToolTip = styled.div`
  position: absolute;
  bottom: ${props => props.position.bottom};
  left: ${props => props.position.left};
`;
const StyledToolTipButton = styled.button`
  background: ${props => props.hover ? '#0366D6' : '#24292E'};
  border-radius: 4px;
  border: none;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  padding: 4px 8px;
  outline: none;
  cursor: pointer;
`;
const StyledToolTipHandle = styled.img`
  width: 45px;
  height: 21px;
  margin-top: 16px;
`;
const ToolTip = ({onClick, position, children}) => {
  const [hover, setHover] = useState(false);
  return (
    <StyledToolTip onClick={onClick} position={position} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <StyledToolTipHandle src={hover ? '/Assets/tooltip_handle_highlight@3x.png': '/Assets/tooltip_handle@3x.png'}/>
      <StyledToolTipButton hover={hover}>{children} <SyncOutlined style={{marginLeft: 6, fontSize: 14}} /></StyledToolTipButton>
    </StyledToolTip>
  )
}

const Index = () => {
  const [selections, setSelections] = useState({countertop: null, panel: null});
  const [showMenu, setShowMenu] = useState(null);
  const [catalogData, setCatalogData] = useState(null);
  const transitions = useTransition(showMenu !== null, null, {
    from: { transform: 'translate3d(256px,0,0)', position: 'absolute', right: 16, top: 16, bottom: 16, },
    enter: { transform: 'translate3d(0px,0,0)' },
    leave: { transform: 'translate3d(256px,0,0)' },
  });
  const tooltipTransitions = useTransition(showMenu === null, null, {
    from: { opacity: 0, },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    fetch('/catalog.json')
      .then((res) => res.json())
      .then((result) => {
        console.log("catalog data downloaded", result);
        setCatalogData(result);
        setSelections({
          countertop: result['countertops'][0].items[0].name,
          panel: result['panels'][0].items[0].name,
        })
      })
  }, []);

  const handleToolTipClick = (catalogName) => {
    if (catalogData) {
      setShowMenu({
        catalogName: catalogName,
        catalog: catalogData[catalogName],
      })
    }
  }

  return (
    <AppContainer>
      {!(selections.countertop && selections.panel) &&
        <StyledSpinnedContainer><Spin size="large" /></StyledSpinnedContainer>
      }
      {(selections.countertop && selections.panel) &&
        <>
          <Suspense fallback={<StyledSpinnedContainer><Spin size="large" /></StyledSpinnedContainer>}>
            <>
              <Background backgroundImageUrl={image_host+"/Renders/"+selections.countertop+"_"+selections.panel+".jpg"}/>
              {tooltipTransitions.map(({ item, key, props }) => item &&
                <animated.div key={key} style={props}>
                  <>
                    <ToolTip onClick={() => handleToolTipClick("countertops")} position={{bottom: '45%', left: '35%'}}>Countertop</ToolTip>
                    <ToolTip onClick={() => handleToolTipClick("panels")} position={{bottom: '80%', left: '20%'}}>Cabinet Panel</ToolTip>
                  </>
                </animated.div>
              )}
            </>
          </Suspense>
          <>
            <a href="https://www.sayhomee.com"><Logo src="/logo-black@3x.png"/></a>
            <Button
                onClick={() => window.open(`/viewer360?imageLink=${"/Renders360/"+selections.countertop+"_"+selections.panel+".jpg"}`)}
                style={{position: 'absolute', left: 0, bottom: 0}}>
              <ExpandOutlined /><span style={{marginLeft: 12}}>3D Preview</span>
            </Button>
            {/* <Button style={{position: 'absolute', left: '50%', bottom: 0, transform: 'translate3d(-50%,0,0)'}}><MailOutlined /><span style={{marginLeft: 12}}>Request Quote</span></Button> */}
            {transitions.map(({ item, key, props }) => item &&
              <animated.div key={key} style={props}>
                {showMenu &&
                  <Menu showMenu={showMenu} selections={selections} setSelections={setSelections} onCloseMenu={() => setShowMenu(null)}/>
                }
              </animated.div>
            )}
          </>
        </>
      }
    </AppContainer>
  );
};

export default Index;
